.toolbar-menu {
  border: 1px solid var(--digital-grey-IV);
}

.toolbar-item {
  /* height: 1.75rem; */
  padding-inline: 0.5rem;
  width: 100%;
  max-width: 250px;
  height: 100%;
  align-items: center;
  justify-content: left;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  /* Animate the background when hovering */
  transition:
    background 0.2s ease-in-out,
    border 0.2s ease-in-out;
  border: 1px solid transparent;
  border-radius: 5px;
  margin-block: 1px;
  cursor: pointer;
}

.toolbar-item-disabled {
  cursor: not-allowed !important;
  background: var(--soft-grey);
  height: 98%;
}

.toolbar-item:hover {
  border: 1px solid var(--digital-grey-IV);
}

.toolbar {
  padding: 5px;
  z-index: 1200;
  top: 0;
  left: 0;
  width: 100vw;
  height: 2.5rem;
  position: sticky;
  background: white;
  border-bottom: 1px solid black;
  align-items: center;
}

/* Disable the input focus/active border for toolbar-search */
/* .toolbar-search:focus, .toolbar-search:active {
    outline: none;
} */

.toolbar-item-key-shortcut {
  font-size: 0.8rem;
  color: #999;
}

.predefined-flow {
  padding-inline: 0.5rem;
  transition: box-shadow 0.2s ease-in-out;
  z-index: 100;
  min-width: 400px;
  /* max-width: 400px; */
  border: 1px solid transparent;
  border-radius: 0px;
  transition:
    background 0.2s ease-in-out,
    border 0.2s ease-in-out,
    border-radius 0.2s ease-in-out;
}

.predefined-flow:hover {
  border: 1px solid var(--digital-grey-IV);
  border-radius: 5px;
}

.predefined-flow-name {
  font-size: 1.2rem;
  color: var(--digital-grey-II);
}

.predefined-flow-plugin {
  font-size: 0.8rem;
}

.dockview-theme-light {
  height: calc(100vh - 2.5rem) !important;
}
