@mixin borderBox {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); //  remove tap highlight color for mobile safari

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); //  remove tap highlight color for mobile safari
  }
}

.msp-slider-base {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: $slider-border-radius-base;
  align-self: center;
  @include borderBox;

  &-rail {
    position: absolute;
    width: 100%;
    background-color: $control-background;
    height: 4px;
    border-radius: 2px;
  }

  &-track {
    position: absolute;
    left: 0;
    height: 4px;
    border-radius: $slider-border-radius-base;
    background-color: tint($font-color, 60%);
  }

  &-handle {
    position: absolute;
    margin-left: -11px;
    margin-top: -9px;
    width: 22px;
    height: 22px;
    cursor: pointer;
    border-radius: 50%;
    background-color: $font-color;
    border: 4px solid $control-background;

    &:hover {
      background-color: $hover-font-color;
    }
  }

  &-mark {
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    font-size: 12px;
  }

  &-mark-text {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: #999;

    &-active {
      color: #666;
    }
  }

  &-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
  }

  &-dot {
    position: absolute;
    bottom: -2px;
    margin-left: -4px;
    width: 8px;
    height: 8px;
    border: 2px solid #e9e9e9;
    background-color: #fff;
    cursor: pointer;
    border-radius: 50%;
    vertical-align: middle;
    &:first-child {
      margin-left: -4px;
    }
    &:last-child {
      margin-left: -4px;
    }
    &-active {
      border-color: tint($font-color, 50%);
    }
  }

  &-disabled {
    background: $default-background;
    opacity: 0.35;
    
    // .msp-slider-base-track {
    //   background-color: $slider-disabledColor;
    // }
    
    .msp-slider-base-handle, .msp-slider-base-dot {
      // border-color: $slider-disabledColor;
      // background-color: color-lower-contrast($font-color, 10%);
      cursor: not-allowed;
    }

    .msp-slider-base-mark-text, .msp-slider-base-dot {
      cursor: not-allowed!important;
    }
  }
}

// .msp-slider-base-vertical {
//   width: 14px;
//   height: 100%;
//   padding: 0 5px;

//   .msp-slider-base {
//     &-rail {
//       height: 100%;
//       width: 4px;
//     }

//     &-track {
//       left: 5px;
//       bottom: 0;
//       width: 4px;
//     }

//     &-handle {
//       margin-left: -5px;
//       margin-bottom: -7px;
//     }

//     &-mark {
//       top: 0;
//       left: 18px;
//       height: 100%;
//     }

//     &-step {
//       height: 100%;
//       width: 4px;
//     }

//     &-dot {
//       left: 2px;
//       margin-bottom: -4px;
//       &:first-child {
//         margin-bottom: -4px;
//       }
//       &:last-child {
//         margin-bottom: -4px;
//       }
//     }
//   }
// }