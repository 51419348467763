@use "sass:color";

.msp-viewport {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: $default-background;

    .msp-btn-link {
        background: rgba(0, 0, 0, 0.2);
    }

}

.msp-viewport-expanded {
    position: fixed;
    z-index: 1000;
}

.msp-viewport-host3d {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    -webkit-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    touch-action: manipulation;

    > canvas {
        background-color: $default-background;
        background-image: linear-gradient(45deg, lightgrey 25%, transparent 25%, transparent 75%, lightgrey 75%, lightgrey),
            linear-gradient(45deg, lightgrey 25%, transparent 25%, transparent 75%, lightgrey 75%, lightgrey);
        background-size: 60px 60px;
        background-position: 0 0, 30px 30px;
    }
}

.msp-viewport-controls {
    position: absolute;
    right: $control-spacing;
    top: $control-spacing;
    width: 32px;
}

.msp-viewport-controls-buttons {
    text-align: right;
    position: relative;
    // opacity: 0.5;
    // &:hover { opacity: 1.0; }

    > div {
        position: relative;
        margin-bottom: 4px;
    }

    button {
        padding: 0;
        text-align: center;
        width: $row-height;
        position: relative;
    }

    .msp-btn-link-toggle-off {
        color: $msp-btn-link-toggle-off-font-color;
    }

    .msp-btn-link:hover {
        color: $hover-font-color;
    }
}

.msp-semi-transparent-background {
    background: $default-background;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.msp-hover-box-wrapper {
    position: relative;

    .msp-hover-box-body {
        visibility: hidden;
        position: absolute;
        right: $row-height + 4px;
        top: 0;
        width: 100px;
        background-color: $default-background;
    }

    .msp-hover-box-spacer {
        visibility: hidden;
        position: absolute;
        right: $row-height;
        top: 0;
        width: 4px;
        height: $row-height;
    }

    &:hover .msp-hover-box-body,
    &:hover .msp-hover-box-spacer {
        visibility: visible;
    }
}

.msp-viewport-controls-panel {
    width: 290px;
    top: 0;
    right: $row-height + 4px;
    position: absolute;
    background: $control-background;

    .msp-control-group-wrapper:first-child {
        padding-top: 0;
    }

    .msp-viewport-controls-panel-controls {
        overflow-y: auto;
        max-height: 400px;
    }
}

/* highlight & toasts */

.msp-highlight-toast-wrapper {
    position: absolute;
    right: $control-spacing;
    bottom: $control-spacing;
    max-width: 95%;

    z-index: 10000;
}

.msp-highlight-info {
    color: $highlight-info-font-color;
    padding: $info-vertical-padding $control-spacing;
    background: $default-background; //$highlight-info-background;
    opacity: 90%;
    max-width: 400px;

    @include non-selectable;
}

.msp-highlight-markdown-row {
    padding-left: $control-spacing;
}

.msp-highlight-simple-row {
    text-align: right;
}

.msp-highlight-info-hr {
    margin-inline: 0px;
    margin-block: 3px;
    border: none;
    height: 1px;
    background-color: $highlight-info-font-color;
}

.msp-highlight-info-additional {
    font-size: 85%;
    display: inline-block;
    color: $highlight-info-additional-font-color;
}

.msp-snapshot-description-wrapper {
    background: color.change($default-background, $alpha: 0.5, $space: rgb);

    position: absolute;
    left: 0;
    top: $control-spacing + $row-height;
    padding: (0.66 * $control-spacing) $control-spacing;

    max-height: 7 * $row-height;
    overflow: hidden;
    overflow-y: auto;

    max-width: 400px;

    a {
        text-decoration: underline;
        cursor: pointer;
        color: $font-color;
    }

    ul, ol {
        padding-left: $control-spacing + 4px;
    }
}