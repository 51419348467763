@mixin non-selectable {
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    /* Rules below not implemented in browsers yet */
    -o-user-select: none;
    user-select: none;

    cursor: default;
}

::-webkit-scrollbar {
    width: 10px;
    height:10px;
}

::-webkit-scrollbar-track {
    //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.8);
    border-radius: 0;
    background-color: color-lower-contrast($control-background, 4%);
}

::-webkit-scrollbar-thumb {
    border-radius: 0;
    //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.9);
    background-color: color-lower-contrast($control-background, 8%);
}

@import 'components/controls-base';
@import 'components/controls';
@import 'components/slider';
@import 'components/misc';
@import 'components/tasks';
@import 'components/viewport';
@import 'components/log';
@import 'components/sequence';
@import 'components/transformer';
@import 'components/toast';
@import 'components/help';
@import 'components/line-graph';