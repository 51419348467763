.plot-container {
  width: 100%;
  height: 100%;
}

.selector-container {
  max-width: 200px;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
  justify-content: center;
}

.flex-group {
  display: grid;
  grid-template-columns: 3fr auto;
}

.settings-plot-icon {
  height: 2rem;
  width: 2rem;
  transition: all 0.5s ease-in-out;
}

.plot-icon-hover {
  transform: rotate(90deg);
  scale: 1.05;
}

.settings-children {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  margin-top: 0;
  opacity: 0;
}

/* Modifications to the existing PlotSettings component in React */
/* You'll need to update the React component to use these styles */
.settings-children-shown {
  max-height: 500px; /* Adjust this value based on your content */
  opacity: 1;
}

.settings-children select,
.settings-children option {
  width: 200px;
}

.plot-settings {
  opacity: 50%;
  transition: all 0.5s ease-in-out;
}

.plot-settings-shown {
  opacity: 1;
}
